import React from "react";
import { Link } from "gatsby";
import MainLogo from "../../static/logos/the_inn_between_main_logo.png";
import PodcastLinks from "../components/PodcastLinks"


const Navbar = (props) => {
    return (
      <section className="cc-container">
        <div className="cc-logo-hero">
          <Link to={'/'}>
            <img className="main-logo" alt="Inn Between Logo" src={MainLogo}/>
          </Link>
        </div>
        <PodcastLinks />
        <nav className="cc-navbar">
          <div className="cc-navitem">
            <Link to="/" activeClassName="active-link">EPISODES</Link>
          </div>
          <div className="cc-navitem">
            <Link to="/about/" activeClassName="active-link">ABOUT</Link>
          </div>
          <div className="cc-navitem">
            <Link to="/support/" activeClassName="active-link">SUPPORT</Link>
          </div>
          <div className="cc-navitem">
            <Link to="/submissions/" activeClassName="active-link">SUBMISSIONS</Link>
          </div>
        </nav>
      </section>
    );
  
}

export default Navbar;
