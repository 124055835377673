import React from "react";

const Footer = (props) => {
  const { config } = props;
  const { copyright } = config;
  if (!copyright) {
    return null;
  }

  return (
    <footer className="footer">
      <p>
        Thank you <a href="https://www.codechefs.dev">Code Chefs</a> for sharing your <a href="https://github.com/vincentntang/codechefs">website code</a>
      </p>
    </footer>
  );
};

export default Footer;
