import React from "react";
import {ReactComponent as PodcastApple} from "../assets/podcastApple.svg";
import {ReactComponent as PodcastGoogle} from "../assets/podcastGoogle.svg";
import {ReactComponent as PodcastYouTube} from "../assets/podcastYoutube.svg";
import {ReactComponent as PodcastAmazon} from "../assets/podcastAmazon.svg";
import {ReactComponent as PodcastSpotify} from "../assets/podcastSpotify.svg";
import {ReactComponent as PodcastRss} from "../assets/podcastRss.svg";
import {ReactComponent as PodcastInstagram} from "../assets/podcastInstagram.svg";

const PodcastLinks = (props) => {
  return (
    <div className="social-links-wrapper">
    <div className="social-links">
      <a className="podcast-links" target="_blank" rel="noreferrer" href="https://podcasts.apple.com/us/podcast/the-inn-between/id1613749949" title="Apple Podcasts">
        <PodcastApple/>
      </a>
      <a className="podcast-links" target="_blank" rel="noreferrer" href="https://open.spotify.com/show/1XWhe1p7nqyZHn1DJ2a17T" title="Spotify">
        <PodcastSpotify/>
      </a>
      <a className="podcast-links" target="_blank" rel="noreferrer" href="https://podcasts.google.com/feed/aHR0cHM6Ly90aGVpbm5iZXR3ZWVucG9kY2FzdC5jb20vcnNzLnhtbA" title="Google Podcast">
        <PodcastGoogle/>
      </a>
      <a className="podcast-links" target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCoGgbRUvDi1deJ14vFCogaQ" title="YouTube">
        <PodcastYouTube/>
      </a>
      <a className="podcast-links" target="_blank" rel="noreferrer" href="https://music.amazon.com/podcasts/235e5951-2526-4cef-95cc-e898f04d1b40" title="Overcast">
        <PodcastAmazon/>
      </a>
      <a className="podcast-links" target="_blank" rel="noreferrer" href="https://pinecast.com/feed/the-inn-between" title="RSS">
        <PodcastRss/>
      </a>
      <a className="podcast-links" target="_blank" rel="noreferrer" href="https://www.instagram.com/theinnbetweenpodcast" title="Instagram">
        <PodcastInstagram/>
      </a>
    </div>
  </div>
  )
}

export default PodcastLinks