const config = {
  s3bucket: 'https://gabrielaandtheinnbetween.com/',
  siteTitle: "Gabriela & The Inn Between", // Site title.
  siteTitleShort: "Inn Between", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "The Inn Between Podcast", // Alternative site title for SEO.
  siteLogo: "/logos/the_inn_between.png", // Logo used for SEO and manifest.
  siteUrl: "https://gabrielaandtheinnbetween.com", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "Gabriela & The Inn Between", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "Gabriela & The Inn Between", // Title of the RSS feed
  // siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  googleAnalyticsID: "", //fix me
  // disqusShortname: "https-vagr9k-github-io-gatsby-advanced-starter", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "MM/DD/YYYY", // Date format for display.
  postsPerPage: 9999, // Amount of posts displayed per listing page.
  userName: "Bailey Loveless", // Username to display in the author segment.
  userEmail: "bailey@theinnbetweenpodcast.com", // Email used for RSS feed's author segment
  // userTwitter: "codechefsdev", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Washington, USA", // User location to display in the author segment.
  userAvatar: "https://www.gabrielaandtheinnbetween.com/logos/baileyloveless.jpg", // User avatar to display in the author segment.
  userDescription: "welcome, weary travelers. the trees are green, the rooms are cozy, & the guests are just a little strange.",
  
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "Instagram",
      url: "https://www.instagram.com/theinnbetweenpodcast/",
      iconClassName: "fa-brands fa-instagram"
    },
    {
      label: "Email",
      url: "mailto:bailey@theinnbetweenpodcast.com",
      iconClassName: "fa fa-envelope"
    }
  ],
  copyright: "Copyright © 2022 The Inn Between", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#ffffff", // Used for setting manifest and progress theme colors.
  backgroundColor: "#47573e" // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
